import React from "react";
import '../../assets/main.css'

class dropdown extends React.Component{

	render(){
		return(
			<div class="card bg-white flex shadow-lg text-center justify-center">
                <div class="grid grid-row-3 mb-2">
                    <div class="row">
                        <img class="w-full h-20" src={require("../../assets/img/network.svg")}></img>
                    </div>
                    <div class="row p-3">
                        <h2 class="text-4xl">Do you need a Business Loan?</h2>  
                    </div>
                    <div class="row">
                        <a href="https://working-capital.com/apply/" className="no-underline"><button class="border-none px-4 cursor-pointer bg-green-600 text-white font-semibold py-3 px-12 mr-2 inline-flex items-center border-transparent hover:bg-green-800 transition">Yes  </button> </a> 
      
                            <button class="border-none px-4 cursor-pointer bg-green-600 text-white font-semibold py-3 px-12 mr-2 inline-flex items-center border-transparent hover:bg-green-800 transition"> <a href="/" className="no-underline">No</a>  </button> 
      
                     </div>
                </div>
             </div>
		);
	}
}

export default dropdown