import React from "react";
import '../../assets/main.css'

class dropdown extends React.Component{
    constructor(props) {
        super(props);
        this.state = {amount: '0', fico: '0'};

        this.ficoChange = this.ficoChange.bind(this);
        this.amountChange = this.amountChange.bind(this);
    }

    ficoChange(event){
        this.setState({fico : event.target.value})
        console.log(event.target.value)
	}

    amountChange(event){
        this.setState({amount : event.target.value})
         console.log(event.target.value)
	}

	render(){
		return(

      <div class="bg-gray-100 w-full pb-2 shadow-lg pt-2 mr-5 mx-auto">
        <p className="text-2xl text-center">Compare Lenders</p>
            <div class="flex flex-wrap justify-center">
                <div class="p-6">
                
                 <p class="text-xl m-0">Loan Amount</p>
                 <select name='fico' class="block appearance-none w-full bg-white border border-solid border-4 border-gray-600 text-black py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
         <option value="400">5,000+</option>
         <option value="450">10,000+</option>
          <option value="550">50,000+</option>
          <option value="650">100,000+</option>
          <option value="750">250,000+</option>
         </select>
                </div>
                 
                <div class="p-6">
                    
                    <p class="text-xl m-0">Personal FICO </p>
                    <select name='fico' class="block appearance-none w-full bg-white border border-solid border-4 border-gray-600 text-black py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
         <option value="400">400-449</option>
         <option value="450">450-549</option>
          <option value="550">550-649</option>
          <option value="650">650-749</option>
          <option value="750">750+</option>
         </select>
                </div>
                <div class="p-6">
                    
                    <p class="text-xl m-0">Time In Business</p>
                    <select name='fico' class="block appearance-none w-full bg-white border border-solid border-4 border-gray-600 text-black py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
         <option value="400">Less than 6 months</option>
         <option value="450">6 months</option>
          <option value="550">1 year</option>
          <option value="650">1-2 year</option>
          
         </select>
         
                </div>
                <div class="p-4 pt-0 text-center">
                <div className="pt-3 pb-4 justify-center">
					<a href="/compare" className="no-underline"><button class="pb-2 border-none cursor-pointer bg-green-600 text-white font-semibold py-3 px-12 mr-2 inline-flex justify-center border-transparent hover:bg-green-800 transition"> Compare </button> </a>
				   
                       <p className="m-0 p-0">OR</p>
         
                   <a class="pt-0 cursor-pointer text-green-600 font-semibold py-3 px-12 mr-2 hover:underline rounded inline-flex justify-center border-transparent no-underline" href="https://working-capital.com/apply/"> Apply to Get Funding</a>  
                </div>
                  </div>
            </div>
            
         </div>
      //   <div class="">
      //     <p class="text-lg">Compare Business Loans</p>
      //     <div class="w-min-full bg-gray-200 w-full h-25 pb-2 pt-2 text-center">
      //       <div class="flex flex-wrap justify-center">
      //           <div class="p-8">
      //           <div class="relative text-center shadow-lg justify-end">
      //   <select name='fico' class="block appearance-none w-full bg-white border border-solid border-4 border-gray-600 text-black py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
      //     <option value="400">400-449</option>
      //     <option value="450">450-549</option>
      //     <option value="550">550-649</option>
      //     <option value="650">650-749</option>
      //     <option value="750">750+</option>
      //   </select>
      //   <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
      //     <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
      //   </div>
      //           </div>
                 
      //           <div class="p-8">
      //           <div class="relative text-center shadow-lg justify-end">
      //   <select name='amount' class="block appearance-none w-full bg-white border border-solid border-4 border-gray-600 text-black py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
      //     <option value="5000">$500-$5,000</option>
      //     <option value="10000">$5,000-$10,000</option>
      //     <option value="50000">$10,000-$50,000</option>
      //     <option value="100000">$50,000-$100,000</option>
      //     <option value="1000000">$100,000+</option>
      //   </select>
      //   <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
      //     <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
      //   </div>
      // </div>
      //           </div>
      //           <div class="p-8">
      //           <div class="relative text-center shadow-lg justify-end">
      //   <select name='amount' class="block appearance-none w-full bg-white border border-solid border-4 border-gray-600 text-black py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
      //     <option value="5000">$500-$5,000</option>
      //     <option value="10000">$5,000-$10,000</option>
      //     <option value="50000">$10,000-$50,000</option>
      //     <option value="100000">$50,000-$100,000</option>
      //     <option value="1000000">$100,000+</option>
      //   </select>
      //   <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
      //     <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
      //   </div>
      // </div>
      //           </div>

      //       </div>
      //    </div>
            
      //           <div className="pt-3 pb-3 justify-center">
			// 		<a href="/compare" className="no-underline"><button class="border-none cursor-pointer bg-green-600 text-white font-semibold py-3 px-12 mr-2 inline-flex justify-center border-transparent hover:bg-green-800 transition"> Compare </button> </a>
			// 	    <br></br>
      //                   or
      //               <br></br>
      //              <a class="cursor-pointer text-green-600 font-semibold py-3 px-12 mr-2 rounded inline-flex justify-center border-transparent no-underline" href="https://working-capital.com/apply/"> Apply to get funding</a>  
      //           </div>
      //           </div>
      //       </div>
		);
	}
}

export default dropdown