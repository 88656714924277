import React from 'react'
import '../assets/main.css'

export default ({ children }) => (
<div>
<meta name='viewport'  content='width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0, viewport-fit=cover' />
  
  <div className="mx-auto pt-8 container content-center">
  <div style={{}}>{children}</div>
  </div>
  
</div>
)
