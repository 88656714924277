import React, { createRef, useEffect } from "react";
import styles from "./navigation.module.css";
import Container from "./container";
import "../assets/main.css";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

import PropTypes from "prop-types";

import {
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
  Highlight,
  ClearRefinements,
  RefinementList,
  Configure,
} from "react-instantsearch-dom";

import algoliasearch from "algoliasearch/lite";

const searchClient = algoliasearch(
  "XL6DMX9MO3",
  "db9c4e02d4b963d52795e7154ec930cd"
);

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      modal: false,
      typing: false,
    };
  }

  toggleVisibilty() {
    this.setState({
      modal: !this.state.modal,
      typing: false,
    });
    console.log(this.state.modal);
  }

  handleButtonClick = () => {
    console.log(this.state.open);
    this.setState((state) => {
      return {
        open: !this.state.open,
      };
    });
  };

  handleKeyPress = (e) => {
    if (e.target.value.trim() == "") {
      this.setState((state) => {
        return {
          typing: false,
        };
      });
    } else {
      this.setState((state) => {
        return {
          typing: true,
        };
      });
    }
  };

  render() {
    return (
      <div className="">
        <Helmet>
          {/* <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/instantsearch.css@7.3.1/themes/reset-min.css" integrity="sha256-t2ATOGCtAIZNnzER679jwcFcKYfLlw01gli6F6oszk8=" crossorigin="anonymous"></link> */}
          <style type="text/css">{`

            `}</style>
        </Helmet>
        {this.state.modal ? (
          <div
            className="absolute w-full h-full z-10 top-0 pt-16 mx-auto"
            style={{
              background: "rgba(0, 0, 0, 0.4)",
            }}
          >
            <div className="mx-auto bg-gray-100 h-100 opacity-100 container rounded text-center">
              <InstantSearch indexName="dev_WC" searchClient={searchClient}>
                <WrappedSearchBox
                  onChange={(e) => this.handleKeyPress(e)}
                  className="w-full"
                  translations={{
                    resetTitle: "Clear your search query.",
                    placeholder: "Search our lenders here...",
                  }}
                  ref={(input) => { input.focus() }} 
                />
                {this.state.typing ? (
                  <Hits hitComponent={Hit} hitsPerPage={2} />
                ) : (
                  <></>
                )}
              </InstantSearch>
            </div>
          </div>
        ) : null}

        <div className="w-min-full sticky inset-x-0 top-0 z-50">
          <section
            class="bg-gray-800 border border-gray-200 shadow-md"
            style={{
              background: "#261671",
            }}
          >
            <header
              toggleFunction={this.handleButtonClick}
              class="container mx-auto flex items-center justify-between flex-shrink"
            >
              <div class="dropdown absolute dropdown group block md:hidden ml-4">
                <div
                  onClick={this.handleButtonClick}
                  class="bg-gray-600 button text-white font-semibold p-1 inline-flex items-center"
                >
                  <svg viewBox="0 0 100 80" width="30" height="30">
                    <rect width="100" height="15" rx="2"></rect>
                    <rect y="30" width="100" height="15" rx="2"></rect>
                    <rect y="60" width="100" height="15" rx="2"></rect>
                  </svg>
                </div>
              </div>
              <div
                class="hidden opacity-25 fixed inset-0 z-40 bg-black"
                id="modal-id-backdrop"
              ></div>

              <nav
                ref={this.mobilemenu__items}
                id="mobile-menu__items"
                class="w-full flex"
              >
                <div
                  class="mx-auto py-3"
                  style={{
                    flex: 2,
                  }}
                >
                  <a href="/" class="h-10">
                    <img
                      class="h-8 pt-0"
                      style={{
                        height: "2.2rem",
                      }}
                      src="https://working-capital.com/img/leaf-logo.svg"
                      alt=""
                    ></img>
                  </a>
                </div>
                <div className="p-2 mr-2">
                  <button
                    className="flex md:hidden justify-center bg-gray-200 border-none h-8 pt-1 w-8 rounded-full cursor-pointer"
                    onClick={() => this.toggleVisibilty()}
                  >
                    <img
                      class="w-full "
                      src={require("../assets/img/search.png")}
                    ></img>
                  </button>
                </div>
                <ul
                  class="hidden md:flex mb-0 mt-0 pt-3 text-center flex-grow justify-end"
                  style={{ listStyle: "none" }}
                >
                  <li
                    class="block mr-6 h-12 border-2 border-solid border-transparent hover:border-gray-400"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      transition: ".5s all",
                    }}
                  >
                    <a
                      href="/business-directory/"
                      class="no-underline text-white hover:text-gray-400 transition  sm:text-sm "
                    >
                      <span class="inline-block align-text-top">
                        Business Directory
                      </span>
                    </a>
                  </li>

                  <li
                    class="block mr-6 h-12 border-2 border-solid border-transparent hover:border-gray-400"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      transition: ".5s all",
                    }}
                  >
                    <a
                      href="/reviews"
                      class="no-underline text-white hover:text-gray-400 transition  sm:text-sm "
                    >
                      <span class="inline-block align-text-top">Reviews</span>
                    </a>
                  </li>

                  <li
                    class="block mr-6 h-12 border-2 border-solid border-transparent hover:border-gray-400"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      transition: ".5s all",
                    }}
                  >
                    <a
                      href="/compare"
                      class="no-underline text-white hover:text-gray-400 transition sm:text-sm "
                    >
                      <span class="inline-block align-text-top">
                        Compare Lenders
                      </span>
                    </a>
                  </li>

                  <li
                    class="block mr-6  h-12 border-2 border-solid border-transparent hover:border-gray-400"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      transition: ".5s all",
                    }}
                  >
                    <a
                      href="/articles"
                      class="no-underline text-white hover:text-gray-400 transition sm:text-sm "
                    >
                      <span class="inline-block align-text-top">Articles</span>
                    </a>
                  </li>

                  <li
                    class="block mr-6 h-12 border-2 border-solid border-transparent hover:border-gray-400"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      transition: ".5s all",
                    }}
                  >
                    <a
                      href="/business-loans"
                      class="no-underline text-white hover:text-gray-400 transition sm:text-sm "
                    >
                      <span class="inline-block align-text-top">Loans</span>
                    </a>
                  </li>

                  <li
                    class="block mr-6 h-12 border-2 border-solid border-transparent hover:border-gray-400"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      transition: ".5s all",
                    }}
                  >
                    <a
                      href="/industries"
                      class="no-underline text-white hover:text-gray-400 transition sm:text-sm "
                    >
                      <span class="inline-block align-text-top">
                        Industries
                      </span>
                    </a>
                  </li>
                  <li
                    className="h-12"
                    style={{
                      marginTop: -4,
                      listStyle: "none",
                    }}
                  >
                    <div>
                      <a
                        class="no-underline"
                        href="https://working-capital.com/apply/"
                      >
                        <button class="hidden lg:block mb-4 cursor-pointer bg-green-700 text-white font-semibold py-3 px-12 mr-10 inline-flex items-center border-transparent hover:bg-green-800 transition">
                          {" "}
                          Apply Now
                        </button>
                      </a>
                    </div>
                  </li>
                  <li>
                    <button
                      className="bg-gray-200 h-10 w-10 border-none rounded-full cursor-pointer"
                      style={{
                        marginTop: -4,
                      }}
                      onClick={() => this.toggleVisibilty()}
                    >
                      <img
                        class="w-full"
                        src={require("../assets/img/search.png")}
                      ></img>
                    </button>
                  </li>
                </ul>
              </nav>
            </header>
          </section>
          <div className="">
            {this.state.open ? (
              <div className="text-center">
                <ul class="list-none transition duration-700 ease-in-out dropdown-menu absoluted de text-gray-700 p-0 m-0">
                  <li class="">
                    <a
                      class="no-underline rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                      href="/reviews"
                    >
                      Reviews
                    </a>
                  </li>
                  <li class="">
                    <a
                      class="no-underline bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                      href="/articles"
                    >
                      Articles
                    </a>
                  </li>
                  <li class="">
                    <a
                      class="no-underline rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                      href="/business-loans"
                    >
                      Loans
                    </a>
                  </li>
                  <li class="">
                    <a
                      class="no-underline rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                      href="/industries"
                    >
                      Industries
                    </a>
                  </li>
                  <li class="">
                    <a
                      class="no-underline rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                      href="/compare"
                    >
                      Compare Lenders
                    </a>
                  </li>
                  <li class="">
                    <a
                      class="no-underline rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                      href="/business-directory/"
                    >
                      Business Directory
                    </a>
                  </li>
                </ul>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function WrappedSearchBox(props){

  let searchInput = React.createRef();

  useEffect(() => {
    searchInput && searchInput.current && searchInput.current.focus()
  }, [searchInput])
  return (
    <SearchBox {...props} ref={searchInput} />
  )
}

function Hit(props) {
  return (
    <div className="items-center w-full">
      <Link
        to={"/reviews/" + props.hit.shortname + "/"}
        style={{
          //borderTop: '1px solid rgba(28,110,164,0.15)',
          borderBottom: "1px solid rgba(28,110,164,0.15)",
        }}
        className="line-clamp p-0 hover:bg-gray-300 no-underline"
      >
        
        <div className="inline-block">
          <img
            className="block h-14 w-16 md:w-32 content-center p-5"
            src={
              "https://working-capital.com/img/lenders/" +
              props.hit.shortname +
              ".png"
            }
            align="left"
            alt={props.hit.name}
          />

          <span className="line-clamp text-left">
          <p className="text-md p-0 m-0 font-bold mb-1">{props.hit.name}</p>
            {props.hit.preview}</span>
        </div>
      </Link>
    </div>
  );
}

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
  hitsPerPage: 2,
};

export default Navigation;
